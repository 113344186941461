import SwiperCore, { Navigation, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "./styles.css";

import "swiper/css";
import "swiper/css/navigation";

import CtaWpp from "../../components/CTAWpp";

import banner_topo from "../../assets/banner_topo.png";
import header3_1_1 from "../../assets/header3_1_1.png";
import header3_1_2 from "../../assets/header3_1_2.png";
import header3_1_3 from "../../assets/header3_1_3.png";
import header3_1_4 from "../../assets/header3_1_4.png";
import header3_2_1 from "../../assets/header3_2_1.png";
import header3_2_2 from "../../assets/header3_2_2.png";
import header3_2_3 from "../../assets/header3_2_3.png";
import header3_2_4 from "../../assets/header3_2_4.png";
import header3_3_1 from "../../assets/header3_3_1.png";
import header3_3_2 from "../../assets/header3_3_2.png";
import header3_3_3 from "../../assets/header3_3_3.png";
import header3_3_4 from "../../assets/header3_3_4.png";
import header3_4_1 from "../../assets/header3_4_1.png";
import header3_4_2 from "../../assets/header3_4_2.png";
import header3_4_3 from "../../assets/header3_4_3.png";
import header3_4_4 from "../../assets/header3_4_4.png";
import header4 from "../../assets/header4.png";

SwiperCore.use([Navigation, Autoplay]);

const data = [
  {
    id: 1,
    image: header3_1_1,
  },
  {
    id: 2,
    image: header3_1_2,
  },
  {
    id: 3,
    image: header3_1_3,
  },
  {
    id: 4,
    image: header3_1_4,
  },
];

const data2 = [
  {
    id: 1,
    image: header3_2_1,
  },
  {
    id: 2,
    image: header3_2_2,
  },
  {
    id: 3,
    image: header3_2_3,
  },
  {
    id: 4,
    image: header3_2_4,
  },
];

const data3 = [
  {
    id: 1,
    image: header3_3_1,
  },
  {
    id: 2,
    image: header3_3_2,
  },
  {
    id: 3,
    image: header3_3_3,
  },
  {
    id: 4,
    image: header3_3_4,
  },
];

const data4 = [
  {
    id: 1,
    image: header3_4_1,
  },
  {
    id: 2,
    image: header3_4_2,
  },
  {
    id: 3,
    image: header3_4_3,
  },
  {
    id: 4,
    image: header3_4_4,
  },
];

const Header = () => {
  return (
    <header id="header">
      <section className="header1">
        <article className="container">
          <div className="left">
            <h1 className="title">
              A solução em <span> Pisos </span> e <span>revestimentos</span>{" "}
              para o seu lar
            </h1>

            <p className="text">
              Oferecemos uma variedade em pisos vinílicos, laminados, rodapés,
              teto vinílico e painel ripado. <span> COM INSTALAÇÃO! </span>
            </p>

            <CtaWpp />
          </div>

          <figure className="right">
            <img src={banner_topo} alt="Banner" />
          </figure>
        </article>
      </section>

      <section className="header2">
        <article className="container">
          <h2 className="title">
            compre PISOS E REVESTIMENTOS ATé 12X SEM JUROS NO Cartão
          </h2>
        </article>
      </section>

      <section className="header3">
        <article className="container">
          <h2 className="title">
            Nossos <span> produtos</span>
          </h2>

          <div className="gallery">
            <div className="item">
              <Swiper
                className="carousel"
                spaceBetween={20}
                slidesPerView={
                  /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
                    navigator.userAgent
                  )
                    ? 1
                    : 1
                }
                loop
                pagination={{ clickable: true }}
                autoplay={{
                  delay: 2000,
                  disableOnInteraction: false,
                }}
              >
                {data.map(({ id, image }) => {
                  return (
                    <SwiperSlide key={id} className="img">
                      <figure>
                        <img src={image} alt={`Exemplo ${id} de Foto`} />
                      </figure>
                    </SwiperSlide>
                  );
                })}
              </Swiper>

              <p className="item_title">Pisos vinílicos</p>

              <p className="item_text">
                Temos os mais variados modelos de pisos vinílicos, das melhores
                marcas do mercado.
              </p>

              <CtaWpp
                text={"Orçamento"}
                hasProduct={true}
                product={"Pisos Vinílicos"}
              />
            </div>

            <div className="item">
              <Swiper
                className="carousel"
                spaceBetween={20}
                slidesPerView={
                  /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
                    navigator.userAgent
                  )
                    ? 1
                    : 1
                }
                loop
                pagination={{ clickable: true }}
                autoplay={{
                  delay: 2000,
                  disableOnInteraction: false,
                }}
              >
                {data2.map(({ id, image }) => {
                  return (
                    <SwiperSlide key={id} className="img">
                      <figure>
                        <img src={image} alt={`Exemplo ${id} de Foto`} />
                      </figure>
                    </SwiperSlide>
                  );
                })}
              </Swiper>

              <p className="item_title">Pisos Laminados</p>

              <p className="item_text">
                Temos os mais variados modelos de pisos laminados, das melhores
                marcas do mercado.{" "}
              </p>

              <CtaWpp
                text={"Orçamento"}
                hasProduct={true}
                product={"Pisos Laminados"}
              />
            </div>

            <div className="item">
              <Swiper
                className="carousel"
                spaceBetween={20}
                slidesPerView={
                  /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
                    navigator.userAgent
                  )
                    ? 1
                    : 1
                }
                loop
                pagination={{ clickable: true }}
                autoplay={{
                  delay: 2000,
                  disableOnInteraction: false,
                }}
              >
                {data3.map(({ id, image }) => {
                  return (
                    <SwiperSlide key={id} className="img">
                      <figure>
                        <img src={image} alt={`Exemplo ${id} de Foto`} />
                      </figure>
                    </SwiperSlide>
                  );
                })}
              </Swiper>

              <p className="item_title">RODAPÉS</p>

              <p className="item_text">
                Temos os mais variados modelos de rodapés em MDF e Poliestireno
                melhores marcas do mercado.
              </p>

              <CtaWpp
                text={"Orçamento"}
                hasProduct={true}
                product={"Rodapés"}
              />
            </div>

            <div className="item">
              <Swiper
                className="carousel"
                spaceBetween={20}
                slidesPerView={
                  /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
                    navigator.userAgent
                  )
                    ? 1
                    : 1
                }
                loop
                pagination={{ clickable: true }}
                autoplay={{
                  delay: 2000,
                  disableOnInteraction: false,
                }}
              >
                {data4.map(({ id, image }) => {
                  return (
                    <SwiperSlide key={id} className="img">
                      <figure>
                        <img src={image} alt={`Exemplo ${id} de Foto`} />
                      </figure>
                    </SwiperSlide>
                  );
                })}
              </Swiper>

              <p className="item_title">PAINEL RIPADO</p>

              <p className="item_text">
                Temos os mais variados modelos de painéis ripados, das melhores
                marcas do mercado.
              </p>

              <CtaWpp
                text={"Orçamento"}
                hasProduct={true}
                product={"Painel Ripado"}
              />
            </div>
          </div>
        </article>
      </section>

      <section className="header4">
        <article className="container">
          <figure className="left">
            <img src={header4} alt="Casa" />
          </figure>

          <div className="right">
            <h1 className="title">
              cONHEÇA O <span> TETO VINÍLICO </span>
            </h1>

            <p className="text">
              A solução ideal para revestir com elegância o teto do seu lar ou
              empresa.
            </p>

            <CtaWpp />
          </div>
        </article>
      </section>
    </header>
  );
};

export default Header;
