import "./styles.css";

import CtaWpp from "../../components/CTAWpp";

import about1 from "../../assets/about1.png";
import about1Mobile from "../../assets/mobile/about1.png";
import about2 from "../../assets/about2.png";
import about4 from "../../assets/about4.png";

const About = () => {
  return (
    <section id="about">
      <article className="about1">
        <div className="container">
          <div className="left">
            <h2 className="title">
              Nosso <span>diferencial </span>
            </h2>

            <figure className="mobile">
              <img src={about1Mobile} alt="Homem colocando Vinílico" />
            </figure>

            <p className="text">
              Além de oferecer os melhores materiais para revestimento, com o
              melhor preço do mercado, também realizamos a instalação. Contamos
              com uma equipe qualificada e experiente para entregar o melhor
              acabamento para o seu ambiente.
            </p>

            <CtaWpp />
          </div>

          <figure className="right">
            <img src={about1} alt="Homem colocando Vinílico" />
          </figure>
        </div>
      </article>

      <article className="about2">
        <div className="container">
          <figure>
            <img src={about2} alt="Logos" />
          </figure>
        </div>
      </article>

      <article className="about3">
        <div className="container">
          <h2 className="title">FALE CONOSCO:</h2>

          <p className="text">
            TIRE SUAS DÚVIDAS POR COM A GENTE PELO WHATSAPP. sERÁ UM PRAZER
            FALAR COM VOCÊ!
          </p>

          <CtaWpp text={"(21) 99837-2597"} />
        </div>
      </article>

      <article className="about4">
        <div className="container">
          <h2 className="title">
            <span>FORMAS DE </span> PAGAMENTO
          </h2>

          <p className="text">
            crédito em até 12x sem juros, débito, PIX e transferência bancária
          </p>

          <figure>
            <img src={about4} alt="FORMAS DE PAGAMENTO" />
          </figure>
        </div>
      </article>
    </section>
  );
};

export default About;
