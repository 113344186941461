import { useEffect, useState } from "react";
import "./styles.css";

import icon from "../../assets/iconwhats.png";

const CtaWpp = (props) => {
  const [text, setText] = useState("Solicitar Orçamento");
  const [product, setProduct] = useState(
    "Olá,%20Gostaria%20de%20fazer%20uma%20cotação%20gratuita"
  );

  useEffect(() => {
    if (props.text !== undefined) setText(props.text);
    if (props.hasProduct) setProduct(`${product}%20de%20${props.product}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <button id="ctaWpp">
      <a
        href={`http://wa.me/+5521998372597?text=${product}`}
        target="_blank"
        rel="noreferrer"
      >
        <figure>
          <img src={icon} alt="Ícone do Whatsapp" />
        </figure>
        <p>{text}</p>
      </a>
    </button>
  );
};

export default CtaWpp;
